<template>
  <div class="welcome-container">
    <div v-if="notAllowed" class="flex flex-col items-center">
      <p v-html="$t('welcome.not_available')" class="text-center text-base"></p>

      <n-cta data-cy="no-code-cta" class="w-56 mt-8 mb-8" design-type="none" @click.native="handleResetSelection">
        {{ $t('welcome.go_back') }}
      </n-cta>
    </div>

    <form v-if="!notAllowed" class="flex flex-col items-center justify-center w-full h-full" @submit.prevent>
      <h3 class="font-bold text-md mb-4" v-html="$t('welcome.select_country')"></h3>

      <n-select
        data-cy="n-select-country"
        class="mb-4"
        design-type="box"
        :placeholder="$t('welcome.select_country_placeholder')"
        :value="selectedCountry ? selectedCountry.label : ''"
        @change="handleOnSelectCountry"
      >
        <n-select-option
          v-for="country in countriesOptions"
          :key="country.value"
          :label="country.label"
          :value="country.value"
        >
          <span>{{ country.label }}</span>
        </n-select-option>
      </n-select>

      <p
        data-cy="country-error"
        v-if="error && !selectedCountry"
        class="-mt-2 text-sm text-red-700"
        v-html="$t('welcome.select_country_error')"
      ></p>

      <h3
        v-if="languagesOptions.length"
        class="font-bold text-md mb-4 mt-4"
        v-html="$t('welcome.select_language')"
      ></h3>

      <n-select
        v-if="languagesOptions.length"
        id="n-select-language"
        data-cy="n-select-language"
        class="mb-4"
        design-type="box"
        :placeholder="$t('welcome.select_language_placeholder')"
        :value="selectedLanguage ? selectedLanguage.label : ''"
        @change="handleOnSelectLanguage"
      >
        <n-select-option 
          v-for="language in languagesOptions" 
          :key="language.value" 
          :option="language"
          :value="language.value"
        >
          <span>{{ language.label }}</span>
        </n-select-option>
      </n-select>

      <p
        data-cy="language-error"
        v-if="error && selectedCountry && languagesOptions.length && !selectedLanguage"
        class="-mt-2 text-sm text-red-700"
        v-html="$t('welcome.select_language_error')"
      ></p>

      <n-cta data-cy="code-cta" class="w-56 mb-4 mt-8" design-type="none" @click.native="handleUniqueCodeClick">
        {{ $t('welcome.cta_code') }}
      </n-cta>

      <n-cta data-cy="no-code-cta" class="w-56 mb-8" design-type="none" @click.native="handleNoUniqueCodeClick">
        {{ $t('welcome.cta_no_code') }}
      </n-cta>

      <p class="text-sm">{{ $t('register.not_mandatory') }}</p>
    </form>
  </div>
</template>

<script>
import { NSelect, NSelectOption } from '@team-uep/n-select';
import NCta from '@team-uep/n-cta';

import { isDevelopmentEnv, isStagingEnv } from '@/helpers';
import { getCountries } from '@/countries';

export default {
  name: 'WelcomeView',

  components: { NSelect, NSelectOption, NCta },

  data() {
    return {
      error: false,
      selectedCountry: null,
      selectedLanguage: null,
      notAllowed: false,
    };
  },

  computed: {
    activeDecliName() {
      return this.$store.state.config.appConfig.activeDecli.name;
    },

    countries() {
      return getCountries(this.activeDecliName).sort((first, second) => {
        if (first.name < second.name) {
          return -1;
        }

        if (first.name > second.name) {
          return 1;
        }

        return 0;
      });
    },

    countriesOptions() {
      return this.countries.map((country) => ({
        label: country.name,
        value: country.id,
        allowed: country.allowed,
        language: country.decli ? country.decli : country.declis,
        decliDomain: country.decliDomain,
      }));
    },

    languagesOptions() {
      if (this.selectedCountry && Array.isArray(this.selectedCountry.language) && this.selectedCountry.language.length) {
        return this.selectedCountry.language.map((language) => ({
          label: language.name,
          value: language.value,
          decliDomain: language.decliDomain,
        }));
      }

      return [];
    },
  },

  methods: {
    handleResetSelection() {
      this.error = false;
      this.selectedCountry = null;
      this.selectedLanguage = null;
      this.notAllowed = false;
    },

    handleOnSelectCountry(countryValue) {
      this.selectedCountry = this.countriesOptions.find((country) => country.value === countryValue);
      this.error = false;
    },

    handleOnSelectLanguage(languageValue) {
      this.selectedLanguage = this.languagesOptions.find((language) => language.value === languageValue);
      this.error = false;
    },

    handleUniqueCodeClick() {
      this.redirect(true);
    },

    handleNoUniqueCodeClick() {
      this.redirect(false);
    },

    redirect(hasCode) {
      if (!this.selectedCountry || (!this.selectedLanguage && this.languagesOptions.length)) {
        this.error = true;
        return;
      }

      const isLocalhost = document.location.hostname.search(/(localhost)/) > -1;
      const isDev = isDevelopmentEnv();
      const isSt = isStagingEnv();

      let domain = this.selectedCountry ? this.selectedCountry.decliDomain : 'com';
      const language = this.selectedLanguage ? this.selectedLanguage.value : null;
      
      let url = '';

      if (!this.selectedCountry.allowed) {
        this.notAllowed = true;
        return;
      }

      if (isLocalhost) {
        if (domain === 'co.uk') {
          url = `http://uk.localhost:8080`
        } else {
          url = `http://${domain}.localhost:8080`
        }
      } else if (isDev) {
        if (domain === 'co.uk') {
          url = `https://uk-valentino-newsletter2021.numberly.dev`
        } else {
          url = `https://${domain}-valentino-newsletter2021.numberly.dev`
        }
      } else if (isSt) {
        if (domain === 'co.uk') {
          url = `https://uk-valentino-newsletter2021.numberly.st`;
        } else {
          url = `https://${domain}-valentino-newsletter2021.numberly.st`;
        }
      } else {
        url = `https://welcome.valentino-beauty.${domain}`;
      }

      url = `${url}/register?hasCode=${hasCode}`;

      if (language) {
        url = `${url}&lang=${language}`;
      }

      window.location = url;
    },
  },

  watch: {
    countrySelectedLanguages(value) {
      if (value.length === 0) {
        this.selectedLanguage = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-container {
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    /* 100vh - header height - center offset desktop */
    min-height: calc(100vh - 98px - 12vh);
  }
}
</style>
